// Styling for the download section
.contact-page{
    .btn-load-spinner {
        display: inline-block;
        width: 16px;
        height: 16px;
        border: 2px solid rgba(181, 181, 181, 0.3);
        border-radius: 50%;
        border-top-color: $theme-primary;
        margin-right: 7px;
        animation: spin .7s ease-in-out infinite;
        -webkit-animation: spin .7s ease-in-out infinite;
      }
      
      @keyframes spin {
        to { -webkit-transform: rotate(360deg); }
      }
      @-webkit-keyframes spin {
        to { -webkit-transform: rotate(360deg); }
      }
}
section.contact {
    text-align: center;
    h2 {
        margin-top: 0;
        margin-bottom: 25px;
        i {
            color: $brand-google-plus;
        }
    }
    ul.list-social {
        margin-bottom: 0;
        li {
            a {
                font-size: 40px;
                line-height: 80px;
                display: block;
                width: 80px;
                height: 80px;
                color: white;
                border-radius: 100%;
            }
            &.social-twitter {
                a {
                    background-color: $brand-twitter;
                    &:hover {
                        background-color: darken($brand-twitter, 5%);
                    }
                }
            }
            &.social-facebook {
                a {
                    background-color: $brand-facebook;
                    &:hover {
                        background-color: darken($brand-facebook, 5%);
                    }
                }
            }
            &.social-google-plus {
                a {
                    background-color: $brand-google-plus;
                    &:hover {
                        background-color: darken($brand-google-plus, 5%);
                    }
                }
            }
        }
    }
}
