$fadeTime:      1s;
.fade{
    // opacity: 0;
    transition: .5s ease-in;
}

.fade-in{
    opacity: 1;
}

