@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$maker-app-primary: mat.define-palette(mat.$deep-purple-palette);
$maker-app-accent:  mat.define-palette(mat.$red-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$maker-app-warn:    mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$maker-app-theme: mat.define-light-theme($maker-app-primary, $maker-app-accent, $maker-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($maker-app-theme);





// CUSTOM OVERWRITING
.mat-form-field-appearance-fill .mat-form-field-flex{
  border-radius: 5px !important;
}

.mat-form-field-flex{
  border: 1px solid rgba(black, 0.12);
}
.mat-focused{
   .mat-form-field-flex{
    border: 1px solid rgba(black, 0.38);
  }
}
.mat-form-field-underline{
  display: none;
}
.mat-form-field-appearance-fill .mat-form-field-flex{
  background-color: transparent;
}


.mat-form-field-subscript-wrapper {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  top: 2.75em;
  margin-top: -0.5em;
  text-align: right;
  z-index: -1;
}
.mat-form-field-invalid{
  .mat-form-field-flex{
    border-color: red;
  }
}