/* You can add global styles to this file, and also import other style files */
@import './scss/bootstrap/bootstrap.scss';
@import './scss/new-age.scss';
@import url('https://use.typekit.net/mak7try.css');

// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

// Alert style
.alert.maker-alert{
  margin-bottom: 0;
  padding-left: 15px;
  padding-right: 40px;
  background-color: transparent;
  border: none;
  color: white;
  a{
    color: white;
    white-space: nowrap;
    text-decoration: underline;
    &:hover{
      text-decoration: none;
    }
  }
  .close:hover{
    color: white;
  }
}

.alert-container{
  background-color: #040404;
}