// Styling for the footer
footer {
    padding: 50px 0;
    color: fade-out(white, .5);
    background: rgb(1, 2, 51);
    background: linear-gradient(0deg, #0b022e 20%, #03055b 100%);

    text-align: center;
    p {
        font-size: 12px;
        margin: 0;
    }

    .footer-nav{
        margin-bottom: 25px;
        li{
            padding: 5px 0;
        }
        ul{
            list-style: none;
            padding-left: 0;
            // padding-right: 25px;
            a{
                color: fade-out(white, .3);
                text-decoration: none;
                &:hover,&.active{
                    color: #fff;
                }
            }
        }
        @include media-breakpoint-up(md){
            ul{
                display: flex;
                justify-content: center;
            }

            li{
                padding: 0 1rem;
                &:first-child{
                    padding-left: 0;
                }
            }
        }
    }
    .footer-logo-wrap{
        img{margin-bottom: 10px;}
    }
}
