.team-page{
   figure{
       text-align: center;
        margin-bottom: 30px;
        h5{ margin-bottom: 0; font-size: 1.4rem;}
   }
   img{ height: 300px; margin-bottom: 12px;}
   
   .blank-person{
     margin: 0 auto;
     padding: 35px 0;
     max-width: 200px;
   }
}


.office{
  height: 600px;
  background: url('/assets/img/office.jpg');
  background-size: cover;
  background-position: center;
}

@include media-breakpoint-up(md){
  .team{}
}