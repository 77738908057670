.services-page{


  .services-section{
    text-align: center;

    h3{ margin-top: 15px; margin-bottom: 15px;}
    // p{ max-width: 550px; margin: 0 auto;}

    
    .sub-section.last{
        margin-bottom: 50px;
    }

    .svg-icon{
        svg{height: 60px;}
    }

    @media (min-width: 768px) {
        text-align: left;
        
        .v-middle-lg{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        .svg-icon{
            svg{height: 60px;}
        }

        .text-wrap{
            // max-width: 500px;
        }
        .text-wrap.left{
            margin-left: 3rem;
        }
        .row-reverse{
            flex-direction: row-reverse ;
        }
    }

    @media (min-width: 992px){
        .text-wrap.left{
            margin-left: 7rem;
        }
    }

    
 
  }

  .img-container{
    position: relative;
    @include media-breakpoint-down(sm){
      // img{display: none;}
    }
    &.web-dev{
      img:nth-child(1){
        height: 150px;
        transform: translate(133px, 45px);
      }
      img:nth-child(2){
        height: 150px;
        transform: translate(166px, 0px);
      }
      img:nth-child(3){
        height: 140px;
        transform: translate(198px, 32px);
      }

      @include media-breakpoint-down(md){
        img:nth-child(1){
          transform: translate(5%, 45px);
        }
        img:nth-child(2){
          transform: translate(15%, 0px);
        }
        img:nth-child(3){
          transform: translate(10%, 32px);
        }
      }

      @include media-breakpoint-down(sm){
        
        img:nth-child(1){
          transform: translate(0, 45px);
        }
        img:nth-child(2){
          transform: translate(15%, 0px);
        }
        img:nth-child(3){
          transform: translate(2%, -20px);
        }
      }
      
    }


    &.custom{
      img:nth-child(1){
        height: 150px;
        transform: translate(94px, 11px);
      }
      img:nth-child(2){
        height: 120px;
        transform: translate(98px, 108px);
      }
      img:nth-child(3){
        height: 145px;
        transform: translate(35px, 13px);
      }
      @include media-breakpoint-down(md){
        img:nth-child(1){
          transform: translate(94px, 11px);
        }
        img:nth-child(2){
          transform: translate(24%, 74px);
        }
        img:nth-child(3){
          transform: translate(24%, -12px);
        }
      }
      @include media-breakpoint-down(sm){
        img:nth-child(1){
          transform: translate(-3vw, 11px);
        }
        img:nth-child(2){
          transform: translate(2%, -5%);
        }
        img:nth-child(3){
          transform: translate(-9%, -27px);
        }
      }
    }

    &.consult{
      img:nth-child(1){
        height: 240px;
        transform: translate(110px, 11px);
      }
      img:nth-child(2){
        height: 120px;
        transform: translate(120px, 0px);
      }
      // img:nth-child(3){
      //   height: 145px;
      //   transform: translate(-160px, 4px);
      // }

      @include media-breakpoint-down(md){
        img:nth-child(1){
          transform: translate(2%, 11px);
        }
        img:nth-child(2){
          transform: translate(5%, -20px);
        }
        // img:nth-child(3){
        //   transform: translate(100%, -100%);
        // }
      }
      // @include media-breakpoint-down(sm){
      //   img:nth-child(3){
      //     transform: translate(-30%, -85%);
      //   }
      // }
    }
  }

  

}