// Styling for the home-banner
// Mobile-first

// @import 'scss/variables';

header.page-banner{
    position: relative;
    margin-top: -92px;
    padding-top: 92px !important;
    height: 40vmax;
    min-height: 400px;
    color: $gray-lighter;
    h5{font-weight: $font-weight-normal;}
    

    background: rgb(2,5,140);
    background: linear-gradient(339deg, rgba(2,5,140,1) 17%, rgba(50,13,124,1) 51%, rgba(123,25,100,1) 78%, rgba(252,47,57,1) 100%);
    background-repeat: no-repeat;

    &.full-height{
        height: calc(100vh - 217px);
        max-height: unset;
    }

    &::before{
        content: '';
        position: absolute;
        top: 0; left: 0;
        width: 100%;
        height: 100%;
        background-image: url('/assets/img/home-banner-bg.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        opacity: .37;
    }

    .display-1{
        margin-bottom: .75rem;
    }

    // .my-auto{margin-bottom: 4% !important;}

    @media (min-width: 992px) {
        max-height: 346px;
        

        &::before{
            background-size: cover;
            // background-position: 80% 40%;
            background-position: center;
        }

    }


}




// background: rgb(226,0,65);
// background: linear-gradient(173deg, rgba(226,0,65,1) 0%, rgba(123,25,100,1) 30%, rgba(50,13,124,1) 65%, rgba(2,5,140,1) 100%);