.work-page{
 

	article{
			// padding: 0 25px;
			h1{text-transform: capitalize;}
	}
	figure{ 
			img{width: 100%;}
	}

	.col-reverse{
			flex-direction: row-reverse ;
	}

	.slick-prev {
		right: 45px;
		left: unset;
		top: -34px;
	}

	.slick-next {
		// right: -25px;
		transform: rotate(180deg);
		top: -49px; //re-position
		right: 10px; //re-position
	}

	@include media-breakpoint-down(sm){
		.col-reverse{
			flex-direction: unset ;
	}
	}
}

.partner-section{
	background-color: #f5f5f5;
	text-align: center;
	color: $black;

	p{
			margin: 0 auto;
			max-width: 650px;
			margin-bottom: 2rem;
	}

	.partner-grid{
			margin: 0 auto;
			max-width: 650px;
			

			.partner-bg{
					background-repeat: no-repeat;
					background-size: 100px auto;
					background-position: center;
					height: 130px;
					-webkit-filter: grayscale(1);
					filter: grayscale(1);
					opacity: .7;
					transition: all .4s;
					&:hover{
							-webkit-filter: unset;
							filter: unset;
							opacity: 1;
					}
			}
			@media (max-width: 767.98px) { 
					.partner-bg{
							background-size: 100px auto;
							height: 100px;
					}
			}
			.voice{background-image: url('/assets/img/partners/voice.svg');}
			.nzc{background-image: url('/assets/img/partners/nzc.svg'); background-size: 150px;}
			.dx{background-image: url('/assets/img/partners/dxmail.svg');}
			
			.cp{background-image: url('/assets/img/partners/cp.svg'); background-size: 85px;} 
			.now{background-image: url('/assets/img/partners/now.svg'); }
			.timg{background-image: url('/assets/img/partners/timg.svg'); background-size: 90px;}
			
			.ph{background-image: url('/assets/img/partners/posthaste.svg'); background-size: 150px;}
			.reside{background-image: url('/assets/img/partners/reside.svg');}
			.asa{background-image: url('/assets/img/partners/asa.svg'); background-size: 130px;}
			
			.wa{background-image: url('/assets/img/partners/wa.svg'); background-size: 120px;}
			.aj{background-image: url('/assets/img/partners/aj.svg');}
			.c1{background-image: url('/assets/img/partners/c1.svg');}

			.de{background-image: url('/assets/img/partners/dynamic.svg');}
			
			.cab{background-image: url('/assets/img/partners/cab.svg');}
			.pie{background-image: url('/assets/img/partners/piefunds.svg'); background-size: 150px;}
			.stj{background-image: url('/assets/img/partners/stjohn.svg');}
			
			.asb{background-image: url('/assets/img/partners/asb.png');}
			.zend{background-image: url('/assets/img/partners/zend.svg'); background-size: 150px;}
			.ffp{background-image: url('/assets/img/partners/ffp.jpg');}


			.ss{background-image: url('../../assets/img/elements/silverstripe-logo.svg'); background-size: 150px;}
			.shopify{background-image: url('../../assets/img/elements/shopify-logo.svg'); background-size: 150px;}
			.squarespace{background-image: url('../../assets/img/elements/squarespace-logo.svg'); background-size: 170px;}

			.caft{background-image: url('../../assets/img/elements/caft-logo.svg'); background-size: 130px;}
			.php{background-image: url('../../assets/img/elements/php-logo.svg'); background-size: 80px;}
			.jquery{background-image: url('../../assets/img/elements/jqury-logo.svg');}
			
			.html{background-image: url('../../assets/img/elements/html-logo.svg');}
			.angular{background-image: url('../../assets/img/elements/angular-logo.svg'); background-size: 150px;}
			.css{background-image: url('../../assets/img/elements/css-logo.svg');}
			.wordpress{background-image: url('../../assets/img/elements/wordpress-logo.svg'); background-size: 150px;}

		
			
	}
}