// Styling for the navbar
#mainNav {
    border-color: fade-out($gray-darker, .95);
    // background: -webkit-linear-gradient(to left, $theme-secondary, $theme-tertiary);
    // background: linear-gradient(to left, $theme-secondary, $theme-tertiary);
    @include transition-all;
    @include heading-font;
    z-index: 1;
    padding: 7px 0.5rem;
    .navbar-brand {
        color: $theme-primary;
        @include heading-font;
        &:hover,
        &:focus {
            color: darken($theme-primary, 10%);
        }
    }
    .navbar-toggler {
        // font-size: 12px;
        // padding: 8px 10px;
        // color: $gray-darker;
    }
    .navbar-nav {
        > li {
            > a {
                font-size: 2rem;
                // @include alt-font;
                &.active {
                    color: $white !important;
                    font-weight: 500;
                    background-color: transparent;
                    &:hover {
                        background-color: transparent;
                    }
                }
            }
            > a,
            > a:focus {
                // color: $gray-darker;
                color: fade-out(white, .3);
                &:hover {
                    color: #fff;
                }
            }
        }
    }
    @media (min-width: 992px) {
        border-color: transparent;
        background-color: transparent;
        .navbar-brand {
            color: fade(white, 70%);
            &:hover,
            &:focus {
                color: white;
            }
        }
        .navbar-nav > li > a,
        .navbar-nav > li > a:focus {
            font-size: 18px;
            color: fade-out(white, .3);
            &:hover {
                color: white;
            }
        }
        &.navbar-shrink {
            border-color: fade-out($gray-darker, .9);
            background-color: white;
            .navbar-brand {
                color: $gray-darker;
                &:hover,
                &:focus {
                    color: $theme-primary;
                }
            }
            .navbar-nav > li > a,
            .navbar-nav > li > a:focus {
                color: $gray-darker;
                &:hover {
                    color: $theme-primary;
                }
            }
        }
    }
}

@media (max-width:991px){
    .navbar-brand{ z-index: 11; }
    
    .menu {
        position: fixed;
        top: 0;
        left: 0;
        opacity: 0;
        visibility: hidden;
        width: 100%;
        height: 100%;
        
        color: #fff;
        background: black;
        transition: .1s ease-in;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 36px;
        
        &.-open {
            visibility: visible;
            opacity: 0.99;
            z-index: 10;
        }
        
        ul { list-style: none; }
        
        li {
            margin: 10px 0;
            a{ color: fade-out(white, .3); }
        }
    }
}

.hamburger-btn {

  cursor: pointer;
  z-index: 11;

  &:focus {
      outline: none;
  }
    
  // New hamburger
  .ham {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transition: transform 400ms;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .hamRotate.active {
    transform: rotate(45deg);
  }
  .line {
    fill:none;
    transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
    stroke:#fff;
    stroke-width:5.5;
    stroke-linecap:round;
  }
  .ham4 .top {
    stroke-dasharray: 40 121;
  }
  .ham4 .bottom {
    stroke-dasharray: 40 121;
  }
  .ham4.active .top {
    stroke-dashoffset: -68px;
  }
  .ham4.active .bottom {
    stroke-dashoffset: -68px;
  }
  @include media-breakpoint-down(sm){
    transform: translateX(0x);
  }

  @include media-breakpoint-down(xs){
    transform: translateX(13px);
  }

}