


header.home-banner {
    position: relative;
    // height: 80vmax;
    height: 692px;
    width: 100%;
    padding-top: 150px;
    min-height: 580px;

    .header-content {
        margin-bottom: 80px;
        &.mx-auto{
            margin-bottom: 50px;
        }

    }
    h4{font-weight: 300;}

    &::before{
        // background-size: 270%;
        // background-position: 90% 40%;
    }

    @media (min-width: 576px) { 
        height: 692px;
        .my-auto{margin-bottom: auto !important;}
     }



    @media (min-width: 992px) {
        padding-top: 0;
        padding-bottom: 0;
        &::before{
            background-size: cover;
            // background-position: 80% 40%;
        }
        .mx-auto{
            margin-bottom: auto;
        }

        

        .header-content {
            margin-bottom: 0;
            text-align: left;
            &.mx-auto{
                margin-bottom: unset;
            }
        }
        .device-container {
            max-width: 325px;
        }
    }
}


