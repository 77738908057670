// Global styling for this template
html,
body {
    width: 100%;
    height: 100%;
}

body {
    @include body-font;
}

a {
    color: $theme-secondary;
    @include transition-all;
    &:hover,
    &:focus {
        color: darken($theme-secondary, 10%);
    }
}

hr {
    max-width: 100px;
    margin: 25px auto 0;
    border-width: 1px;
    border-color: fade-out($gray-darker, .9);
}

hr.light {
    border-color: white;
}


p {
    // font-family: adelle-condensed,serif;
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 20px;
}

section {
    padding-top: 70px;
    padding-bottom: 70px;
    &.sub-section{
        padding-top: 40px;
        padding-bottom: 40px;
    }

    @include media-breakpoint-up(md){
        padding: 85px 0;
        &.sub-section{
            padding-top: 40px;
            padding-bottom: 40px;
        }
    }
    @include media-breakpoint-up(lg){
        padding: 100px 0;
        &.sub-section{
            padding-top: 50px;
            padding-bottom: 50px;
        }
    }
}

.header-content{
    h3{font-weight: 300;}
}

.bg-gray{
    background-color:  #f5f5f5 !important;
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) { 
    .portfolio-container{
        grid-gap: 10px;
    }
 }

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) { 
    .portfolio-container{
        grid-gap: 15px;
    }
 }


//It seems like there's a style sheet missing for the BingMaps package so the following styling is to make the map more usable
#map {
    cursor: grab;
}

#map:active {
    cursor: grabbing;
}

#map ul{
    list-style-type: none;
    position: absolute;
    bottom: 0;
    margin-bottom: 0px;
    padding-left: 0px;
    background-color: rgb(240, 240, 240);
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

#map li{
    float: left;
    user-select: none;
}

#map .ol-zoom, #map .ol-rotate, #map .ol-attribution button {
    display: none;
}