// CSS for slider UI feature

// This mainly code for logo slider in the Home page and Services page

.slide-container {
  margin: 35px auto;
}




// Theme homepage
.partner-section .slick-slide {
  margin: 0 20px;
  text-align: center;

  img {
    height: 100px;
    max-width: 150px;
    margin: 0 auto;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
    opacity: .7;
    transition: all .4s;

    &:hover {
      -webkit-filter: unset;
      filter: unset;
      opacity: 1;
    }
  }

  @include media-breakpoint-down(sm) {
    img {
      height: 60px;
      max-width: 100px;
    }
  }

  @include media-breakpoint-down(xs) {
    img {
      height: 50px;
      max-width: 80px;
    }
  }
}




/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;

  position: absolute;
  top: 50%;

  display: block;

  width: 20px;
  height: 30px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);

  cursor: pointer;

  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  svg path{stroke: $gray-500;}

  @include media-breakpoint-down(sm) {
    display: none !important;
  }
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
  svg path{stroke: $gray-700;}
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: .25;
}


.slick-prev {
  left: -15px;
  // top: -35.5px;
  z-index: 200;
}

[dir='rtl'] .slick-prev {
  right: -25px;
  left: auto;
}

.slick-next {
  right: -25px;
  transform: rotate(180deg);
  top: 36px; //re-position
  // right: 10px; //re-position
}

[dir='rtl'] .slick-next {
  right: auto;
  left: -25px;
}